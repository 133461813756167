import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const BinaryCounter = () => {
  
      const BinaryDisplay = styled.div`
      font-size: 4rem;
      font-family: 'Fira Code', monospace;
      padding: 10px;
      background-color: rgb(20, 20, 20);
      border-radius: 10px;
      margin-top: 20px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
  
      @media screen and (min-width: 1400px) and (max-width: 1800px) {
        font-size: 3.6rem;
        padding: 12px;
        margin-top: 10px;
      }

      @media screen and (min-width: 1000px) and (max-width: 1399px) {
        font-size: 3rem;
        padding: 10px;
        margin-top: 8px;
      }

      @media screen and (min-width: 750px) and (max-width: 999px) {
        font-size: 2rem;
        padding: 6px;
        margin-top: 4px;
      }

      @media screen and (max-width: 750px) {
        font-size: 2rem;
        padding: 3px;
        margin-top: 2px;

      }
  `;
  // Initial binary state
  const [binaryValue, setBinaryValue] = useState('00000000');
  const [incrementing, setIncrementing] = useState(true); // To track the counting direction

  useEffect(() => {




    // Function to update the binary value
    const updateBinaryValue = () => {
      const currentValue = parseInt(binaryValue, 2); // Convert binary to decimal
      const nextValue = incrementing ? currentValue + 1 : currentValue - 1;

      if (nextValue > 255) {
        // Reached 11111111, so start decrementing
        setIncrementing(false);
      } else if (nextValue < 0) {
        // Reached 00000000, so start incrementing
        setIncrementing(true);
      }

      // Update the binary value, keeping it an 8-bit binary string
      setBinaryValue(nextValue.toString(2).padStart(8, '0'));
    };

    // Set an interval to update every 100ms
    const intervalId = setInterval(updateBinaryValue, 100);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [binaryValue, incrementing]); // Dependencies: binaryValue and incrementing

  return (
    <div style={styles.container}>
      <h2 style={styles.headingBinarCounter} >Binary Counter (8-Digit)</h2>
      <BinaryDisplay>

        {binaryValue}
      </BinaryDisplay>

    </div>
  );
};

// Styles
const styles = {
  container: {
    borderRadius: '10px',
    width: '60%',
    height: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(26, 29, 35)',
    color: 'white',
  },
  headingBinarCounter: {
        fontSize: '15px',
        '@media screen and (min-width: 1400px) and (max-width: 1800px)': {
          fontSize: '15px',
        },
        '@media screen and (min-width: 1000px) and (max-width: 1399px)': {
          fontSize: '13px',
        },
        '@media screen and (max-width: 650px)': {
          margiinBottom: '20px',
        }
  },
  binaryDisplay: {
    fontSize: '4rem',
    fontFamily: '"Fira Code", monospace',
    padding: '10px',
    backgroundColor: 'rgb(20, 20, 20)',
    borderRadius: '10px',
    marginTop: '20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.6)',
  },
};

export default BinaryCounter;