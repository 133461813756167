import React from 'react';
import './harvardCertificate.css'
import harvardLogo from '../../assets/images/harvardLogo.png';
import harvardCertificate from '../../assets/images/harvardCertificate.jpg';

const HarvardCertificate = () => {
    return (
        <section className='harvardCertificate'>
                  <div className='metaCertificateContainerLeft'> 
            <a href='https://certificates.cs50.io/143ff523-c8ad-4df9-879e-30455b708d1e.pdf?size=letter'>
                <img 
                    className='metaCertificatePicture'
                    src={harvardCertificate}
                    alt='Meta Front-end Certificate'
                />
            </a>
            </div>
            <div className='metaCertificateContainerRight'>
                <a href='https://www.harvard.edu/'>
                    <img
                        className='harvardLogo'
                        src={harvardLogo}
                        alt='Meta Logo'
                    />
                </a>
                <div className='certificateDescriptionContainerHarvard'>
                    <h3 className='harvardCertificateTitle'>CS50: Introduction to Computer Science – Harvard University</h3>
                    <p>
                    I completed CS50: Introduction to Computer Science, a rigorous and in-depth course that gave me a solid foundation in computer science principles and programming. Throughout the course, I explored various key aspects of CS, including algorithms, data structures, memory management, and web development, while working with multiple programming languages and their libraries and frameworks such as:
                    </p>
                    <ul>
                        <li><span>C</span> – eveloped a deep understanding of low-level programming and memory management.</li>
                        <li><span>Python</span> – Applied high-level programming for scripting and automation.</li>
                        <li><span>JavaScript</span> – Built dynamic, interactive web applications and worked with front-end development.</li>
                    </ul>
                    <p>
                    Each week, I tackled challenging projects, from solving algorithmic problems to developing functional software and web-based applications, all of which helped sharpen my problem-solving skills and coding proficiency.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default HarvardCertificate;
