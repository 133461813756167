import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carouselCoding.css'; // Add your styles here
import TypingPythonCode from '../typingCode/typingPythonCode/TypingPythonCode';
import TypingJavaScriptCode from '../typingCode/typingPythonCode/TypingJavaScriptCode';
import TypingPHPCode from '../typingCode/typingPythonCode/TypingPHPCode';
import TypingJavaCode from '../typingCode/typingPythonCode/TypingJavaCode';
import TypingHaskellCode from '../typingCode/typingPythonCode/TypingHaskellCode';
import TypingRCode from '../typingCode/typingPythonCode/TypingRCode';


// Custom Arrow Components
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '-40px', zIndex: 1 }} // Customize button position
      onClick={onClick}
    >
      <button style={styles.button}>◀</button>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '-40px', zIndex: 1 }} // Customize button position
      onClick={onClick}
    >
      <button style={styles.button}>▶</button>
    </div>
  );
};

const CarouselCoding = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="carousel-container" style={styles.container}>
      <Slider {...settings}>
        <div className="carousel-slide">
          <h2 style={styles.title}>Python</h2>
          <div style={styles.content}><TypingPythonCode /></div>
          <p style={styles.text}>Python is the first programming language I learned.</p>
        </div>
        <div className="carousel-slide">
          <h2 style={styles.title}>JavaScript</h2>
          <div style={styles.content}><TypingJavaScriptCode /></div>
          <p style={styles.text}>JavaScript is my main language, I can develop both Front- and Backend with JS.</p>
        </div>
        <div className="carousel-slide">
          <h2 style={styles.title}>PHP</h2>
          <div style={styles.content}><TypingPHPCode /></div>
          <p style={styles.text}>I learned PHP, while I was doing my internship at GiB GmbH.</p>
        </div>
        <div className="carousel-slide">
          <h2 style={styles.title}>Java</h2>
          <div style={styles.content}><TypingJavaCode /></div>
          <p style={styles.text}>Java is the language, I mostly use for my university.</p>
        </div>
        <div className="carousel-slide">
          <h2 style={styles.title}>Haskell</h2>
          <div style={styles.content}><TypingHaskellCode /></div>
          <p style={styles.text}>I leaned Haskell in my functional Programming course.</p>
        </div>
        <div className="carousel-slide">
          <h2 style={styles.title}>R</h2>
          <div style={styles.content}><TypingRCode /></div>
          <p style={styles.text}>R is the language that showes you the real power of statistics</p>
        </div>
      </Slider>
    </div>
  );
};

// Styling for the buttons and carousel
const styles = {
  container: {
    width: '80%',
    margin: '0 auto',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '14px',
    textAlign: 'center',
  },
  button: {
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'inline-block',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    cursor: 'pointer',
    outline: 'none',
  },
};

export default CarouselCoding;