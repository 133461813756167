import React, { useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, Sphere, Text, Line } from '@react-three/drei';
import * as THREE from 'three';

// Qubit Bloch Sphere component
const BlochSphere = ({ position, label, differences, color, vectorPosition, additionalText, onHover, active }) => {
  const radius = 5;  // Radius of the Bloch sphere
  const state0 = [0, radius, 0];  // |0⟩ state
  const state1 = [0, -radius, 0];  // |1⟩ state
  const stateVector = vectorPosition || [3, 2, 1];  // Arbitrary quantum state vector

  // Colors
  const arrowColor = color || new THREE.Color(0xFF6347);  // Customizable color for each qubit
  const sphereColor = color || 0x3498db;  // Vibrant blue or customizable color
  const state0Color = 0x00FF00;  // Green for |0⟩
  const state1Color = 0xFF0000;  // Red for |1⟩

  return (
    <group
      position={position}
      onPointerOver={() => onHover(label)}
      onPointerOut={() => onHover('')}
    >
      {/* Create the Bloch Sphere */}
      <Sphere args={[radius, 64, 64]} position={[0, 0, 0]}>
        <meshStandardMaterial color={sphereColor} opacity={0.8} transparent={true} />
      </Sphere>

      {/* Basis states |0⟩ and |1⟩ as dots */}
      <mesh position={state0}>
        <sphereGeometry args={[0.1, 16, 16]} />
        <meshStandardMaterial color={state0Color} />
      </mesh>

      <mesh position={state1}>
        <sphereGeometry args={[0.1, 16, 16]} />
        <meshStandardMaterial color={state1Color} />
      </mesh>

      {/* State vector as an arrow */}
      <Line
        points={[0, 0, 0, ...stateVector]} 
        color={arrowColor}
        lineWidth={2}
      />

      {/* Larger text for selected qubit */}
      {active && (
        <>
          <Text
            position={[0, radius + 1.5, 0]}  // Above the Bloch sphere
            fontSize={1.5}  // Larger text size for better visibility
            color="white"
            anchorX="center"
            anchorY="middle"
          >
            {label}
          </Text>

          {/* Differences */}
          <Text
            position={[0, -radius - 2, 0]}  // Below the Bloch sphere
            fontSize={0.8}  // Medium font size for differences
            color="white"
            anchorX="center"
            anchorY="middle"
          >
            {differences}
          </Text>

          {/* Additional explanation */}
          <Text
            position={[0, -radius - 4, 0]}  // Below differences text
            fontSize={0.6}  // Smaller font size for additional explanation
            color="yellow"
            anchorX="center"
            anchorY="middle"
          >
            {additionalText}
          </Text>
        </>
      )}
    </group>
  );
};

// Main App component with camera and controls
const QuantumQubits = () => {
  const [hoveredQubit, setHoveredQubit] = useState('');

  return (
    <>
      {/* Title Text */}
      <div style={{
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '40px',  // Larger title size
        color: '#fff',
        fontWeight: 'bold',
        textShadow: '2px 2px 5px rgba(0,0,0,0.7)',
        zIndex: 10,
      }}>
        Quantum Qubits in Quantum Computing
      </div>

      {/* Side Information Panel */}
      <div style={{
        position: 'absolute',
        top: '100px',
        right: '20px',
        width: '300px',
        padding: '20px',
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        borderRadius: '10px',
        fontSize: '18px',
        zIndex: 10,
        transition: 'transform 0.3s ease',
        transform: hoveredQubit ? 'translateX(0)' : 'translateX(100%)',
      }}>
        <h2 style={{ fontSize: '24px' }}>Qubit Information</h2>
        {hoveredQubit ? (
          <>
            <p><strong>{hoveredQubit}</strong></p>
          </>
        ) : (
          <p>Hover over a qubit to get more information.</p>
        )}
      </div>

      {/* 3D Canvas for Bloch Sphere representing Qubits */}
      <Canvas
        style={{
          height: '500px',
          width: '800px',  // Fixed width
          backgroundColor: '#1e1e1e',
          border: '1px solid #fff',
          borderRadius: '10px',
          margin: '100px auto', // Margin for spacing from top
          zIndex: 0,
        }}
        camera={{ position: [0, 10, 20], fov: 60 }}
      >
        {/* Lights */}
        <ambientLight intensity={0.3} />
        <directionalLight position={[5, 5, 5]} intensity={1} />

        {/* First Qubit (Qubit 1) */}
        <BlochSphere
          position={[-15, 0, 0]}
          label="Qubit 1 (|0⟩ + |1⟩)"
          differences="Superposition state"
          color={0x1F77B4}  // Blue
          vectorPosition={[3, 2, 1]}
          additionalText="Example of a balanced superposition"
          onHover={setHoveredQubit}
          active={hoveredQubit === 'Qubit 1 (|0⟩ + |1⟩)'}
        />

        {/* Second Qubit (Qubit 2) */}
        <BlochSphere
          position={[0, 0, 0]}
          label="Qubit 2 (|0⟩ - |1⟩)"
          differences="Superposition state with phase flip"
          color={0xFF7F0E}  // Orange
          vectorPosition={[-3, -2, 1]}
          additionalText="Phase flip affects measurement outcome"
          onHover={setHoveredQubit}
          active={hoveredQubit === 'Qubit 2 (|0⟩ - |1⟩)'}
        />

        {/* Third Qubit (Qubit 3) */}
        <BlochSphere
          position={[15, 0, 0]}
          label="Qubit 3 (|0⟩)"
          differences="Pure |0⟩ state"
          color={0x2CA02C}  // Green
          vectorPosition={[0, 5, 0]}
          additionalText="Pure state, no superposition"
          onHover={setHoveredQubit}
          active={hoveredQubit === 'Qubit 3 (|0⟩)'}
        />

        {/* Fourth Qubit (Qubit 4) */}
        <BlochSphere
          position={[30, 0, 0]}
          label="Qubit 4 (|0⟩ + i|1⟩)"
          differences="Complex superposition with phase"
          color={0xD62728}  // Red
          vectorPosition={[0, 5, 3]}
          additionalText="Complex phase alters superposition"
          onHover={setHoveredQubit}
          active={hoveredQubit === 'Qubit 4 (|0⟩ + i|1⟩)'}
        />

        {/* Orbit Controls for Camera */}
        <OrbitControls />
      </Canvas>
    </>
  );
};

export default QuantumQubits;