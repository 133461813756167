import React, { useState, useEffect } from 'react';
import './main.css';

import Homepage from '../homepage/Homepage';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

import { Routes, Route } from 'react-router-dom';
import HeroSectionCoding from '../../components/typingCode/heroSectionCoding/HeroSectionCoding';

// Full-page component that is displayed first
const MyComponent = () => {
  return (
    <div className="full-page-component">
      {<HeroSectionCoding />}
      <div >
        {/* Additional content to make the page scrollable */}
      </div>
    </div>
  );
};

const Main = () => {
  const [scrolling, setScrolling] = useState(false);

  // Scroll event listener to toggle navbar visibility based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) { // Change '200' to any desired scroll threshold
        setScrolling(true); // Navbar appears when scrolled more than 200px
      } else {
        setScrolling(false); // Navbar is hidden if scrolled above 200px
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on component unmount
    };
  }, [scrolling]);

  return (
    <div className='mainPageContainer'>
      {/* Display MyComponent initially */}
      <MyComponent />

      {/* Render Navbar, Routes, and Footer only after scrolling */}
      {scrolling && (
        <div>
          <Navbar />
          <Homepage />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Main;