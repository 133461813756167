import React, {useState, useEffect} from 'react'
import './heroSection.css'

import { AiFillGithub, AiFillLinkedin, AiOutlineMail, AiFillInstagram } from 'react-icons/ai';

import  profile1 from '../../assets/images/profile1.jpeg';


const HeroSection = () => {

  const [mobileScreen, setMobileScreen] = useState(window.innerWidth <= 650); // Set initial state based on window size

  useEffect(() => {
    // Function to check and update the screen size
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 650);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section id='home' className='heroSection'>
      <div className='heroSectionContainer'>

          <div className='heroSectionContainerLeft'>
              <h1>Seyed Ramtin Hosseini</h1>
              <h2>Software Engineer | CS Student | Physics Student</h2>
                <p>
                  I'm a <span className='aBoldText'>Computer Science</span> and <span className='aBoldText'>Physics</span> student with a strong foundation in <span className='aBoldText'>programming</span>, <span className='aBoldText'>physics</span>, and <span className='aBoldText'>mathematics</span>. 
                  My passion lies in solving complex problems with logic, technology, and science.
              </p>
              <p>
                Whether it's developing web applications, diving into quantum computing, or exploring AI, I'm always looking for ways to make an impact.
              </p>
              <h3 >I'm located in the city of Munich</h3>

              <a href='#contact' className='buttonToContactMe'>Contact Me</a>
          </div>
          <div className='heroSectionContainerRigt'>
              <div className='profilePictureContainer'>
                 {!mobileScreen && <img className='profilePicture' src={profile1} alt='my profile picture' /> }
              </div>
              <div className='heroSectionIconSocialMedia'>
                <a href="https://github.com/dr-ramix" target="_blank" rel="noopener noreferrer" aria-label="Github">
                  <AiFillGithub className="socialIcon" />
                </a>
                <a href="https://www.linkedin.com/in/seyed-ramtin-hosseini-637915212/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <AiFillLinkedin className="socialIcon" />
                </a>
                <a href="mailto:ramtin.hosseini1382@gmail.com" aria-label="Email">
                  <AiOutlineMail className="socialIcon" />
                </a>
                <a href="https://www.instagram.com/dr.ramix/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <AiFillInstagram className="socialIcon" />
                </a>
              </div>
          </div>
      </div>
    </section>
  )
}

export default HeroSection
