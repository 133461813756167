import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPython, // Python
  faJsSquare, // JavaScript
  faJava, // Java
  faHtml5, // HTML5
  faCss3Alt, // CSS
  faGithub, // GitHub
  faReact, // React.js
  faNode, // Node.js
  faBootstrap, // Bootstrap
  faPhp, // PHP  // MySQL, MongoDB, SQL
} from '@fortawesome/free-brands-svg-icons'; // Correct import path
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { aliases } from '@fortawesome/free-brands-svg-icons/fa42Group';


// The component for displaying the skill icons in a responsive grid
const SkillIcons = () => {
  const skills1 = [
    { name: 'Python', icon: faPython },
    { name: 'JavaScript', icon: faJsSquare }, // Used faJsSquare for JS
    { name: 'Java', icon: faJava },// Placeholder for Haskell, using JS icon
  ];
  const skills2 = [
    { name: 'CSS', icon: faCss3Alt }, // Used faCss3Alt for CSS
    { name: 'GitHub', icon: faGithub },
    { name: 'Express.js', icon: faJsSquare }, // Using JS icon as placeholder


  ];
  const skills3 = [
    { name: 'Bootstrap', icon: faBootstrap },
    { name: 'PHP', icon: faPhp },
    { name: 'SQL', icon: faDatabase }, // Reusing faDatabase for SQL

  ];

  const skills4 = [
    { name: 'HTML5', icon: faHtml5 },
    { name: 'Haskell', icon: faJsSquare }, 
    { name: 'Node.js', icon: faNode },
  ];

  const skills5 = [
    { name: 'React.js', icon: faReact },
    { name: 'Sass', icon: faCss3Alt }, // Placeholder for Sass, using CSS icon
    { name: 'Git', icon: faGithub },
  ];

  return (
    <div style={styles.iconsMainContainer}>
        <div style={styles.iconsContainer}>
        {skills1.map((skill) => (
            <div key={skill.name} style={styles.iconWrapper}>
            <FontAwesomeIcon icon={skill.icon} size="3x" />
            <p style={styles.iconLabel}>{skill.name}</p>
            </div>
        ))}
        </div>
        <div style={styles.iconsContainer}>
        {skills2.map((skill) => (
            <div key={skill.name} style={styles.iconWrapper}>
            <FontAwesomeIcon icon={skill.icon} size="3x" />
            <p style={styles.iconLabel}>{skill.name}</p>
            </div>
        ))}
        </div>
        <div style={styles.iconsContainer}>
        {skills3.map((skill) => (
            <div key={skill.name} style={styles.iconWrapper}>
            <FontAwesomeIcon icon={skill.icon} size="3x" />
            <p style={styles.iconLabel}>{skill.name}</p>
            </div>
        ))}
        </div>
        <div style={styles.iconsContainer}>
        {skills4.map((skill) => (
            <div key={skill.name} style={styles.iconWrapper}>
            <FontAwesomeIcon icon={skill.icon} size="3x" />
            <p style={styles.iconLabel}>{skill.name}</p>
            </div>
        ))}
        </div>
        <div style={styles.iconsContainer}>
        {skills5.map((skill) => (
            <div key={skill.name} style={styles.iconWrapper}>
            <FontAwesomeIcon icon={skill.icon} size="3x" />
            <p style={styles.iconLabel}>{skill.name}</p>
            </div>
        ))}
        </div>
    </div>
  );
};

const styles = {
    iconsMainContainer: {
        width: '90%',
        margin: '0 5%',
        display: 'flex',
        height: '30%',
        alignItems: 'center',
    },
  iconsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',  // Corrected typo here
    justifyContent: 'space-between',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: 'white',
  },
  iconLabel: {
    marginTop: '5px',
    fontSize: '14px',
    color: '#f1fa8c', // Yellow for icon labels
  },
};

export default SkillIcons;