import React, {useEffect, useState} from 'react';
import './interests.css';

import proteinCopilotPicture from '../../assets/images/proteinAiGeneratedPicture.png'

import proteinAiGenerated from '../../assets/videos/videoOfProteinAiGenerated.mp4';
import proteinAiGenerated02 from '../../assets/videos/videoOfProteinAiGenerated02.mp4';
import proteinAiGenerated03 from '../../assets/videos/videoOfProteinAiGenerated03.mp4';
import proteinAiGenerated04 from '../../assets/videos/videoOfProteinAiGenerated04.mp4';

import GifVideo from '../gifVideo/GifVideo';

import deepLearningImage from '../../assets/images/deepLearning02.jpg';


import qubit from '../../assets/images/qubit.webp';
import quantumComputer from '../../assets/images/googles-quantenprozessor.webp';
import deepLearningQuantum from '../../assets/images/deepLeraningQuantum_optimized.png';

import QuantumQubits from '../3dmodels/QuantumQubits';

const Interests = () => {

    const [mobileScreen, setMobileScreen] = useState(window.innerWidth <= 1199); // Set initial state based on window size

    useEffect(() => {
      // Function to check and update the screen size
      const handleResize = () => {
        setMobileScreen(window.innerWidth <= 1199);
      };
  
      // Add event listener for resize
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    return (
        <section id='intersts' className='intersts'>
            <h3 className='interstsTitle'>My Passions</h3>
            <div className='interstsFirstPage'>
                <div>
                    <div className='interstsListBio'>
                        <div className='interstContainerBio'>
                            <div className='interstTextBio'>
                                <h4 className='interstTextTitleBio'>AI for Biology and Physics</h4>
                                <p className='interstTextDescriptionBio'>
                                I'm interested in how AI is transforming biology and physics, particularly through 3D modeling. In biology, 
                                AI helps generate models of proteins and molecules, speeding up drug discovery and molecular research. In physics and engineering, AI-driven 
                                simulations enhance design and innovation, making complex structures easier to model and optimize. AI’s ability to bridge these fields excites me, 
                                as it opens up new possibilities for solving real-world challenges.
                                </p>
                            </div>
                            <img 
                                className='pictureOfCopilotForProtein'
                                src={proteinCopilotPicture}
                                alt='picture of ai copilot for proteins'
                            />
                            <div className='proteinsVideoList'>
                                <GifVideo video={proteinAiGenerated} />
                                <GifVideo video={proteinAiGenerated02} />
                                <GifVideo video={proteinAiGenerated03} />
                                <GifVideo video={proteinAiGenerated04} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="interstsClimateChange">
                        <div className="interstsClimateChangeText">
                            <h3>Passion for Climate Action: Bridging AI, Physics, and Clean Energy</h3>
                            <p>I’m driven by the idea that technology can be the key to unlocking a 
                            sustainable future. My fascination with climate change and clean energy fuels 
                            my ambition to blend computer science and physics in groundbreaking ways. 
                            From AI-powered simulations that predict environmental shifts to energy modeling that optimizes renewable resources, 
                            I see endless potential in using data and innovation to solve the world’s most pressing challenges. My goal is to continue 
                            learning and contributing to this vital cause, where science and technology unite for a cleaner, greener plane</p>
                        </div>
                        <img 
                            className='deepLearningImage'
                            src={deepLearningImage}
                            alt='Deep Learning Model'
                        />
                    </div>
                </div>
            </div>
            <div className='interstsSecondPage'>
                <div className='interstsQuantumComputingContainer'>
                    <div className='interstsQuantumComputingText'>
                        <h3>Exploring the Infinite: My Passion for <span>Quantum Computing</span></h3>
                        <p>I am captivated by the limitless potential of <b>quantum computing</b>, 
                        where the rules of classical physics bend and reality transforms.
                        Imagine a world where problems deemed unsolvable today—like optimizing complex systems 
                        or simulating quantum phenomena—become solvable with the power of 
                        <b>qubits</b>. 
                        My curiosity lies in harnessing this 
                        <b>quantum revolution</b>, 
                        pushing the boundaries of what computers can achieve, and unlocking new frontiers in
                        <b>cryptography</b>, <b>AI</b>, and <b>scientific discovery</b>. 
                        As I continue to dive deeper into this exciting realm, my ambition is to contribute to a future where 
                        <b>quantum computers</b> reshape industries and challenge the very fabric of how we perceive 
                        computation.
                        </p>
                    </div>
                    <div className='interstsQuantumComputingPictures'>
                        <img 
                            className='qubitPicture'
                            src={qubit}
                            alt="Qubit"
                        />
                       
                        <img 
                            className='quantumDeepLearningPicture'
                            src={deepLearningQuantum}
                            alt='Quantum Deep Learning'
                        />
                        <img 
                            className='quantumComputerPicture'
                            src={quantumComputer}
                            alt='Google Quantum Computer'
                        />
                    </div>
                </div>
            </div>
            <div className='quantum3DModelContainer'>
                {!mobileScreen && <QuantumQubits />}
            </div>

        </section>
    );
}

export default Interests;
