
import  blogImg        from '../src/assets/images/reactAppProjectBlog_optimized.png';
import  socialMediaImg from '../src/assets/images/reactAppProjectSocialmedia.png';
import  landingPageImg from '../src/assets/images/reactAppProjectLandingpage_optimized.png';
import  ecommerceImg   from '../src/assets/images/reactAppProjectEcommerce_optimized.png';
import  booking        from '../src/assets/images/reactAppProjectBooking_optimized.png';
import  adminPanel     from '../src/assets/images/reactAppProjectAdminPanelLight_1_optimized.png';


export const projects = [
    {
      id: 1,
      img: blogImg,
    },
    {
      id: 2,
      img: socialMediaImg,
    },
    {
      id: 3,
      img: landingPageImg,
    },
    {
      id: 4,
      img: ecommerceImg,
    },
    {
      id: 5,
      img: booking,
    },
    {
      id: 6,
      img: adminPanel,
    },
  ];