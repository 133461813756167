import React from 'react';
import  './mathSkill.css'
import BinaryCounter from '../binaryCounter/BinaryCounter';


const MathSkill = () => {
    return (
        <section className='mathSkill'>
            <div className='mathSkillLeft'>
                    <span className='mathSkillTitleLittle'>The</span> 
                    <span>Language</span> 
                    <span className='mathSkillTitleLittle'>of</span>
                    <span className='mathSkillTitleLittle'>the</span>
                    <span>Universe</span>
                    <span className='mathSkillTitleBig'>MATHEMATIC</span>
            </div>
            <div className='mathSkillRight'>
                <div className='mathSkillDescription'>
                            <h3>Math Skills</h3>
                            <div className='skillsWrapperMath'>
                                <div className="i-title-math">
                                    <div className="i-title-wrapper-math">
                                        <div className="i-title-item-math">Calculus</div>
                                        <div className="i-title-item-math">Linear Algebra</div>
                                        <div className="i-title-item-math">Statistics</div>
                                        <div className="i-title-item-math">Matrix Calculation</div>
                                        <div className="i-title-item-math">Regression</div>
                                        <div className="i-title-item-math">Integral</div>
                                        <div className="i-title-item-math">Discrete Math</div>
                                        <div className="i-title-item-math">Inductive Statistics</div>
                                        <div className="i-title-item-math">Logic</div>
                                        <div className="i-title-item-math">Boolean Algebra</div>
                                        <div className="i-title-item-math">Calculus</div>
                                        <div className="i-title-item-math">Linear Algebra</div>
                                        <div className="i-title-item-math">Statistics</div>
                                    </div>
                                </div>
                            </div>
                            <p>
                                As a CS and Physics major at LMU, 
                                I blend Calculus, Linear Algebra, 
                                and Statistics to solve real-world problems. 
                                From modeling physical systems to optimizing code and analyzing data, 
                                my math skills drive innovation across disciplines. 
                                I use math not just as a tool, but as a language to bridge theory and application.
                            </p>
                </div>
                <BinaryCounter />
            </div>
        </section>
    );
}

export default MathSkill;
