import React, { useState, useEffect } from 'react';
import './minimalLoadingPage.css';  // Updated CSS file

const MinimalLoadingPage = ({ onLoadingComplete }) => {
  const [progress, setProgress] = useState(0);

  // Simulate loading progress with a 3-second duration
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 1 : prev));
    }, 30); // Progress increments by 1% every 30ms (for a total of 3 seconds)

    if (progress === 100) {
      onLoadingComplete();
    }

    return () => clearInterval(interval);
  }, [progress, onLoadingComplete]);

  return (
    <div className="loading-container">
      {/* Binary background */}
      <div className="binary-background">
        {Array.from({ length: 80 }, (_, i) => (
          <div key={i} className="binary-column">
            {[...Array(15)].map((_, j) => (
              <span key={j} className="binary-digit">{Math.random() > 0.5 ? '1' : '0'}</span>
            ))}
          </div>
        ))}
      </div>

      <div className="loading-elements">
        {/* Flickering dots */}
        <div className="dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        {/* Loading text - now says "Computing" */}
        <div className="loading-text">
          <h2>Computing...</h2>
        </div>
        {/* Minimal progress bar */}
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default MinimalLoadingPage;