import React from 'react';
import './lmuSection.css'

import lmuBuilding from '../../assets/images/lmuBuilding.jpg';
import lmuLogo from '../../assets/images/lmulogo.png';

const LmuSection = () => {
    return (
        <section id='lmuSection' className='lmuSection'>
            <div className='lmuSectionContainer'>

                <div className='lmuContainerLeft'>
                    <h2>My Education at LMU Munich</h2>
                    <a href="https://www.lmu.de/en/" target="_blank" rel="noopener noreferrer" aria-label="LMU">
                        <img className='lmuLogo' src={lmuLogo} alt="LMU Logo" />
                    </a>
                    <div className='lmuBuildingPictureWrapper'>
                        <div className='lmuBuildingPictureContainer'>
                        </div>
                        <img className='lmuBuildingPicture' src={lmuBuilding} alt='LMU Building' />
                    </div>
                </div>
                <div className='lmuContainerRight'>
                    <article>
                        <span>
                            I'm studying Computer Science and Physics at Ludwig-Maximilians-University known as the university of Munich. <br/> 
                            My applied subject is Statistic, which helps me to understand Data Science better.
                        </span>
                        <p>“Ludwig-Maximilians-University" of Munich is not just a world-class institution; it’s a place where tradition meets innovation. LMU is ranked #38 globally and #1 in Germany, LMU is known for its strong academic programs, research, and impact.</p>
                        <p>LMU is home to 42 Nobel laureates.</p>
                        <p>This university has been founded in 1472</p>
                    </article>
                    <div className='rankingContainer'>
                        <div className='rankingWrapper'>
                            <div className='worldRanking'><span>38</span></div>
                            <span>Global Ranking</span>
                        </div>
                        <div className='rankingWrapper'>
                            <div className='germanyRanking'><span>1</span></div>
                            <span>Ranking in Germany</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LmuSection;
