import './App.css';

import { Routes, Route } from 'react-router-dom';

import { useState } from 'react';
import MinimalLoadingPage from './pages/minimalLoadingPage/MinimalLoadingPage';
import Homepage from './pages/homepage/Homepage';
import Main from './pages/main/Main';


const App = () => {
  const [loading, setLoading] = useState(true);

  // Function to end the loading state
  const handleLoadingComplete = () => {
    setLoading(false);
  };

  

  return (
    <div className='app'>
      {loading ? (
        <MinimalLoadingPage onLoadingComplete={handleLoadingComplete} />
      ) :(
        <Main />
       )
      }
    </div>
  );
};


export default App