import React from 'react';
import './projectSection.css'

import {projects} from '../../projectsData';
import Project from '../project/Project';

const ProjectSection = () => {
    return (
        <section className='projects'>
                <h1 className="projects-title">Some of my porjects</h1>
            <div className='projects-list'>
            {
                projects.map(project => (
                    <Project key={project.id} img={project.img} />
                ))
            }

            </div>
        </section>
    );
}

export default ProjectSection;
