import React from 'react';
import Typing from 'react-typing-effect';

const TypingPythonCode = () => {
  return (
    <div style={styles.codeContainer}>
      <pre style={styles.pre}>
        <code style={styles.code}>
          <span style={styles.keyword}>class</span> <span style={styles.function}>QuantumAI</span>: {'\n'}
          &nbsp;&nbsp;<span style={styles.keyword}>def</span> <span style={styles.function}>__init__</span>(<span style={styles.variable}>self</span>): {'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.variable}>self</span>.<span style={styles.variable}>state</span> <span style={styles.operator}>=</span> <span style={styles.string}>"superposition"</span>{'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.variable}>self</span>.<span style={styles.variable}>goal</span> <span style={styles.operator}>=</span> <span style={styles.string}>"solve AGI"</span>{'\n'}
          
          &nbsp;&nbsp;<span style={styles.keyword}>def</span> <span style={styles.function}>simulate_future</span>(<span style={styles.variable}>self</span>, <span style={styles.variable}>iterations</span>): {'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.builtin}>for</span> <span style={styles.variable}>i</span> <span style={styles.keyword}>in range</span>(<span style={styles.variable}>iterations</span>): {'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.variable}>self</span>.<span style={styles.function}>optimize</span>(){'\n'}

          &nbsp;&nbsp;<span style={styles.keyword}>def</span> <span style={styles.function}>optimize</span>(<span style={styles.variable}>self</span>): {'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.builtin}>print</span>(

          <Typing
            text={[
              '"Enhancing quantum state..."', 
              '"Running AI optimization..."',
              '"Collapsing wave function..."',
              '"Exploring multiverse..."'
            ]}
            speed={100}
            eraseSpeed={50}
            typingDelay={500}
            eraseDelay={2000}
            displayTextRenderer={(text) => (
              <span style={styles.string}>
                {text}
              </span>
            )}
          />){'\n'}

          &nbsp;&nbsp;<span style={styles.keyword}>def</span> <span style={styles.function}>achieve_goal</span>(<span style={styles.variable}>self</span>): {'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.builtin}>print</span>(<span style={styles.string}>"Goal: Artificial General Intelligence Achieved!"</span>){'\n'}
        </code>
      </pre>
    </div>
  );
};

// Responsive styles
const styles = {
  codeContainer: {
    backgroundColor: '#2b2b2b', // Dark background for code block
    padding: '16px',
    borderRadius: '8px',
    width: '86%', // Responsive width for smaller screens
    maxWidth: '600px', // Maximum width for larger screens
    margin: '0 auto',
    fontSize: '18px',
    boxSizing: 'border-box',
    '@media screen and  (max-width: 650px)': {
      padding: '10px',
    }
  },
  pre: {
    backgroundColor: '#2b2b2b',
    color: '#f8f8f2',
    padding: '8px',
    borderRadius: '8px',
    fontSize: '10px',
    lineHeight: '1.6',
    overflowX: 'auto',
    boxSizing: 'border-box',
    '@media screen and  (max-width: 650px)': {
      padding: '6px',
      fontSize: '10px',
      lineHeight: '1.2',
    }
  },
  code: {
    fontFamily: '"Fira Code", "Consolas", "Courier New", monospace',
    whiteSpace: 'pre-wrap', // Wrap text on smaller screens
  },
  keyword: {
    color: '#ff79c6', // Pink for keywords
  },
  function: {
    color: '#8be9fd', // Light blue for function names
  },
  variable: {
    color: '#50fa7b', // Green for variables
  },
  operator: {
    color: '#ffb86c', // Orange for operators
  },
  string: {
    color: '#f1fa8c', // Yellow for strings
  },
  builtin: {
    color: '#bd93f9', // Purple for built-in functions
  },
};

// Media query styles (applied for smaller screens)
const mediaQueries = `
  @media (max-width: 768px) {
    .codeContainer {
      width: 95%;
      font-size: 8px;
    }
  }

  @media (max-width: 480px) {
    .codeContainer {
      width: 100%;
      font-size: 6px;
    }
  }
`;

export default TypingPythonCode;