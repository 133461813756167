import React from 'react';
import './homepage.css';
import HeroSection from '../../components/heroSection/HeroSection';
import Skills from '../../components/skills/Skills';
import LmuSection from '../../components/lmuSection/LmuSection';
import MathSkill from '../../components/mathSkill/mathSkill';
import Courses from '../../components/courses/Courses';
import ProjectSection from '../../components/projectsSection/ProjectSection';
import Interests from '../../components/interests/Interests';
import Contact from '../../components/contact/Contact';

const Homepage = () => {
  return (
    <div className='homepage'>
        <HeroSection />
        <Skills />
        <MathSkill />
        <LmuSection />
        <Courses />
        <ProjectSection />
        <Interests />
        <Contact />
    </div>
  )
}

export default Homepage;
