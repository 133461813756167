import React from 'react';
import Typing from 'react-typing-effect';

const TypingRCode = () => {
  return (
    <div style={styles.codeContainer}>
      <pre style={styles.pre}>
        <code style={styles.code}>
          <span style={styles.keyword}>QuantumAI</span> &lt;- {'function'}() {'{'}{'\n'}
          &nbsp;&nbsp;<span style={styles.variable}>state</span> &lt;- <span style={styles.string}>"superposition"</span>{'\n'}
          &nbsp;&nbsp;<span style={styles.variable}>goal</span> &lt;- <span style={styles.string}>"solve AGI"</span>{'\n'}

          &nbsp;&nbsp;<span style={styles.keyword}>optimize</span> &lt;- {'function'}() {'{'}{'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.builtin}>print</span>(<Typing
            text={[
              '"Enhancing quantum state..."', 
              '"Running AI optimization..."',
              '"Collapsing wave function..."',
              '"Exploring multiverse..."'
            ]}
            speed={100}
            eraseSpeed={50}
            typingDelay={500}
            eraseDelay={2000}
            displayTextRenderer={(text) => (
              <span style={styles.string}>
                {text}
              </span>
            )}
          />);{'\n'}
          &nbsp;&nbsp;{'}'}{'\n'}

          &nbsp;&nbsp;<span style={styles.keyword}>achieveGoal</span> &lt;- {'function'}() {'{'}{'\n'}
          &nbsp;&nbsp;&nbsp;&nbsp;<span style={styles.builtin}>print</span>(<span style={styles.string}>"Goal: Artificial General Intelligence Achieved!"</span>);{'\n'}
          &nbsp;&nbsp;{'}'}{'\n'}
          {'}'}
        </code>
      </pre>
    </div>
  );
};

const styles = {
  codeContainer: {
    backgroundColor: '#2b2b2b', 
    padding: '16px',
    borderRadius: '8px',
    width: '86%',
    maxWidth: '600px',
    margin: '0 auto',
    fontSize: '18px',
    boxSizing: 'border-box',
  },
  pre: {
    backgroundColor: '#2b2b2b',
    color: '#f8f8f2',
    padding: '8px',
    borderRadius: '8px',
    fontSize: '10px',
    lineHeight: '1.6',
    overflowX: 'auto',
    boxSizing: 'border-box',
  },
  code: {
    fontFamily: '"Fira Code", "Consolas", "Courier New", monospace',
    whiteSpace: 'pre-wrap',
  },
  keyword: {
    color: '#ff79c6',
  },
  function: {
    color: '#8be9fd',
  },
  variable: {
    color: '#50fa7b',
  },
  operator: {
    color: '#ffb86c',
  },
  string: {
    color: '#f1fa8c',
  },
  builtin: {
    color: '#bd93f9',
  },
};

export default TypingRCode;