import React from 'react';
import './courses.css'
import HarvardCertificate from '../harvardCertificate/HarvardCertificate';
import MetaCertificate from '../metaCertificate/MetaCertificate';

const Courses = () => {
    return (
        <section className='courses'>
            <div className='coursesTitleContianer'>
                <h2 className='coursesTitle'>My Certificates</h2>
            </div>
            <MetaCertificate />
            <HarvardCertificate />
        </section>
    );
}

export default Courses;
