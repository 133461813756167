import React, { useEffect, useState } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [mobileScreen, setMobileScreen] = useState(window.innerWidth <= 650); // Set initial state based on window size

  useEffect(() => {
    // Function to check and update the screen size
    const handleResize = () => {
      setMobileScreen(window.innerWidth <= 650);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to only run once when the component mounts

  return (
    <header>
          <nav className="navbar">
            <ul className="navbarList">
      {!mobileScreen && (
        <>
              <li className="navbarListElement">
                <a className='navbarListElementLink' href='#home'>
                  Home
                </a>
              </li>
              <li className="navbarListElement">
              <a className="navbarListElementLink" href="#skills">

                  Skills
              </a>
              </li>
              <li className="navbarListElement">
                <a className="navbarListElementLink" href="#lmuSection">
                  Education
                </a>
              </li>
              <li className="navbarListElement">
                <a className="navbarListElementLink" href="#project">
                  Projects
                </a>
              </li>
              <li className="navbarListElement">
                <a className="navbarListElementLink" href="#intersts">
                  Interests
                </a>
              </li>

        </>
      )}
              <li className="navbarListElement">
                <a className="navbarListElementLink" href="#contact">
                  Contact Me
                </a>
              </li>
            </ul>
          </nav>
    </header>
  );
};

export default Navbar;