import "./contact.css"

import Phone from "../../assets/images/phone_optimized.png"
import Email from "../../assets/images/email_optimized.png"
import Adrress from "../../assets/images/address_optimized.png"


import { useRef, useState } from "react"
import emailjs from '@emailjs/browser';


const Contact = () => {

  

    const formRef = useRef();
    const [done, setDone] = useState(false);
    const [text, setText] = useState();

    const handleSubmit = (e) => {
        // to avoid from refreshing the page after submiting
        e.preventDefault()

        emailjs.sendForm(
          'service_6n18suo', //service ID
          'template_sfqmdrr', // template ID
           formRef.current, 'CaprO0nwRFDacb8DN') //user ID
        .then((result) => {
            console.log(result.text);
            setDone(true)
            setText("")
        }, (error) => {
            console.log(error.text);
        });
    }
  return (
    <div id="contact" className='c'>
        <div className="c-bg"></div>
        <div className="c-wrapper">
            <div className="c-left">
                <h1 className="c-title"> Let's talk about your Ideas!</h1>
                <div className="c-info">
                    <div className="c-info-item">
                        <img
                         src={Phone}
                         alt="" 
                         className="c-icon"/>
                         +49 17664 960 118
                    </div>
                    <div className="c-info-item">
                        <img
                         src={Email}
                         alt="" 
                         className="c-icon"/>
                         ramtin.hosseini1382@gmail.com
                    </div>
                    <div className="c-info-item">
                        <img
                         src={Adrress}
                         alt="" 
                         className="c-icon"/>
                        Munich, Germany
                    </div>
                </div>
            </div>
            <div className="c-right">
                <p className="c-desc">
                <b>What`s your story?</b>Let's get in touch. Always available for
                your ideas, if the right project comes along me.
                </p>
                <form ref={formRef} onSubmit={handleSubmit} >

                    <input  type="text" placeholder="Name" name="user-name"/>
                    <input  type="text" placeholder="Subject" name="user-subject"/>
                    <input  type="email" placeholder="Email" name="user-email"/>

                    <textarea  rows="5" placeholder="Message" name="message" value={text}/>

                    <button>Submit</button>

                    {done && <p className="c-thank">Thank you...! We stay in contact.</p>}

                </form>
            </div>
        </div>
    </div>
  )
}
export default Contact;

