import React from 'react';
import './skills.css'

import CarouselCoding from '../carouselCoding/CarouselCoding';
import SkillIcons from '../skillIcon/SkillIcon';




const Skills = () => {
    return (
        <section id="skills" className='skills'>
        <div className='skillsContainerLeft'>
        <h2>Progrmming Language I can</h2>
             <CarouselCoding />
        </div>
        <div className='skillsContainerRight'>
            <div>
                <h2  className="skillsContainerRightTitle">I'm a Front- and Backend Engineer</h2>
                <div className='skillsWrapper'>
                    <div className="i-title">
                            <div className="i-title-wrapper">
                                <div className="i-title-item">HTML/CSS</div>
                                <div className="i-title-item">Backend</div>
                                <div className="i-title-item">Database</div>
                                <div className="i-title-item">SQL/NoSQL</div>
                                <div className="i-title-item">React JS</div>
                                <div className="i-title-item">Node JS</div>
                                <div className="i-title-item">CI/CD</div>
                                <div className="i-title-item">REST API</div>
                                <div className="i-title-item">Express</div>
                                <div className="i-title-item">Django</div>
                                <div className="i-title-item">Frontend</div>
                                <div className="i-title-item">Backend</div>
                                <div className="i-title-item">Database</div>
                            </div>
                        </div>
                </div>
            </div>
            <SkillIcons />
        </div>
        </section>
    );
}

export default Skills;
