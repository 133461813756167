import React from 'react';
import './mateCertificate.css'

import metaCertificatePicture from '../../assets/images/metaFrontEndCertificate.jpg';
import metaLogo from '../../assets/images/metaLogo.png'

const MetaCertificate = () => {
    return (
        <section className='metaCertificate'>
            <div className='metaCertificateContainerLeft'> 
            <a href='https://www.coursera.org/account/accomplishments/professional-cert/25BPTZBI8A6E'>
                <img 
                    className='metaCertificatePicture'
                    src={metaCertificatePicture}
                    alt='Meta Front-end Certificate'
                />
            </a>
            </div>
            <div className='metaCertificateContainerRight'>
                <a href='https://www.meta.com/de/en/'>
                    <img
                        className='metaLogo'
                        src={metaLogo}
                        alt='Meta Logo'
                    />
                </a>
                <div className='certificateDescriptionContainerMeta'>
                    <h3>Meta Front-End Developer Specialization</h3>
                    <p>
                    I completed the Meta Front-End Developer Specialization, 
                    which focused heavily on front-end programming and modern web development practices.
                    Through nine comprehensive courses, 
                    I developed a strong foundation in key programming skills, including:
                    </p>
                    <ul>
                        <li><span>JavaScript Programming</span> – Core language of the web for creating dynamic and interactive sites.</li>
                        <li><span>React (Basic & Advanced)</span> – Mastered component-based architecture, state management, and advanced React concepts.</li>
                        <li><span>HTML & CSS</span> – Built responsive, well-structured websites with deep knowledge of markup and styling.</li>
                        <li><span>Version Control</span> – Managed code efficiently with Git and GitHub for collaboration and version management.</li>
                        <li><span>Coding Interview Preparation</span> – Strengthened problem-solving skills and coding techniques for technical interviews.</li>
                    </ul>
                    <p>
                    The specialization also included a capstone project, where I applied my programming knowledge to build a fully functional web application, solidifying my front-end development expertise.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default MetaCertificate;
