import React, { useEffect, useState } from 'react';
import Typing from 'react-typing-effect';
import './heroSectionCoding.css'; // Import the CSS for this component

const HeroSection = () => {
  const [firstLineTyped, setFirstLineTyped]     = useState(false);
  const [secondLineTyped, setSecondLineTyped]   = useState(false);
  const [thirdLineTyped, setThirdLineTyped]     = useState(false);
  const [fourthLineTyped, setFourthLineTyped]   = useState(false);

  // Trigger the typing of the first line after a slight delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setFirstLineTyped(true);
    }, 2000); // Wait 2 seconds before starting the typing effect on the first line

    return () => clearTimeout(timer);
  }, []);

   // Trigger the typing of the first line after a slight delay
   useEffect(() => {
    const timer = setTimeout(() => {
      setSecondLineTyped(true);
    }, 5500); // Wait 2 seconds before starting the typing effect on the first line

    return () => clearTimeout(timer);
  }, []);

   // Trigger the typing of the first line after a slight delay
   useEffect(() => {
    const timer = setTimeout(() => {
      setThirdLineTyped(true);
    }, 8000); // Wait 2 seconds before starting the typing effect on the first line

    return () => clearTimeout(timer);
  }, []);


   // Trigger the typing of the first line after a slight delay
   useEffect(() => {
    const timer = setTimeout(() => {
      setFourthLineTyped(true);
    }, 12000); // Wait 2 seconds before starting the typing effect on the first line

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="heroContainer">
      <div className="textWrapper">
        {/* First Line: Typing Effect, it stays after typing */}
        <h1 className="headingHeroCoding">
          <Typing
            text={["Hi, I'm Seyed Ramtin Hosseini."]}
            speed={60}
            eraseSpeed={80}
            typingDelay={100}
            eraseDelay={99999999999}
            cursorClassName="typing-cursor"
            onFinishedTyping={() => setFirstLineTyped(true)}
          />
        </h1>
        <div className='subTextWrapper'>
        {/* Second Line: Typing Effect */}
        {firstLineTyped && (
          <h2 className="subHeading">

            <Typing
              text={[' I’m a Software Engineer, CS Student and Physics Student.']}
              speed={50}
              eraseSpeed={80}
              typingDelay={20}
              eraseDelay={99999999999}
              cursorClassName="typing-cursor"
            />
          </h2>
        )}

        {/* Third Line: Typing Effect */}
        {secondLineTyped && (
          <h2 className="subSubHeading">
            Passionate about{' '}
            <Typing
              text={[
                'Quantum Computing.',
                'AI.',
                'Data Engineering.',
                'Web Development.',
              ]}
              speed={25}
              eraseSpeed={20}
              typingDelay={400}
              eraseDelay={1800}
              cursorClassName="typing-cursor"
            />
          </h2>
        )}

        {/* Fourth Line: Typing Effect */}
        {/*thirdLineTyped && (
          <h2 className="subSubHeading">
            Proficient in{' '}
            <Typing
              text={['Python.', 'JavaScript.', 'Java.', 'PHP' ,'Haskell.', 'R.']}
              speed={25}
              eraseSpeed={20}
              typingDelay={400}
              eraseDelay={1800}
              cursorClassName="typing-cursor"
            />
          </h2>
        )*/}
        </div>
        {fourthLineTyped && (
          <div className='lastMessage'>
          <h2 >
            Scroll Down To Know Me 
          </h2>
          <span>
          ↓
          </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroSection;