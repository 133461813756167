import React from 'react';
import './gifVideo.css'

const GifVideo = (props) => {
    return (
        <div className='proteinsVideoItem'>
        <video
            className="gif-video"
            autoPlay
            loop
            muted
            playsInline
            defaultPlaybackRate={20}
            style={{
                objectFit: 'cover',
                filter: 'grayscale(100%)', // Apply black and white filter
            }}

            >
        <source src={props.video} type="video/mp4" />
        Your browser does not support the video tag.
        </video>
    </div>
    );
}

export default GifVideo;
