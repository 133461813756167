import React from 'react'
import './footer.css'

import { AiFillGithub, AiFillLinkedin, AiOutlineMail, AiFillInstagram } from 'react-icons/ai';

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='footerContainer'>
          <div className='footerLeft'>
            <div className='signatur'>
              <span>@2024</span>
              <span>Version 1.01</span>
              <span>Made by S. Ramtin Hosseini</span>
            </div>
          </div>
          <div className='footerRight'>
            <div className='footerIconSocialMedia'>
                  <a href="https://github.com/dr-ramix" target="_blank" rel="noopener noreferrer" aria-label="Github">
                    <AiFillGithub className="footerSocialIcon" />
                  </a>
                  <a href="https://www.linkedin.com/in/seyed-ramtin-hosseini-637915212/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                    <AiFillLinkedin className="footerSocialIcon" />
                  </a>
                  <a href="mailto:ramtin.hosseini1382@gmail.com" aria-label="Email">
                    <AiOutlineMail className="footerSocialIcon" />
                  </a>
                  <a href="https://www.instagram.com/dr.ramix/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                    <AiFillInstagram className="footerSocialIcon" />
                  </a>
              </div>
          </div>
        </div>
          <div className='footerLastQoute'>
          <qoute>“When we are no longer able to change a situation, we are challenged to change ourselves.”</qoute>
            <span>Viktor E. Frankl</span>
          </div>
    </footer>
  )
}

export default Footer
